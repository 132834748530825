<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    :title="dbType == 1 ? '同db公司迁移' : '不同db公司迁移'"
    v-model="dialogFormVisible"
    width="700px"
  >
    <el-scrollbar :maxHeight="contentStyleObj">
      <el-form
        :model="temp"
        label-position="right"
        label-width="170px"
        :rules="rules"
        ref="dataForm"
        size="small"
      >
        <el-form-item label="原代账公司名称:" prop="orgName">
          <el-input v-model="temp.orgName" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item label="导入原迁移公司:">
          <el-input
            style="width: 240px; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >选择文件</el-button
          >
          <el-button type="primary" @click="analyzeExcel()" size="small"
            >上传</el-button
          ><a href="javascript:;" @click="loadForm()" style="margin-left: 10px"
            >下载模板</a
          >
        </el-form-item>
        <el-form-item label="原迁移公司名称:" prop="comName">
          <el-input v-model="temp.comName" style="width: 240px"></el-input
          ><el-icon style="margin: 0 10px; cursor: pointer" @click="add">
            <circle-plus />
          </el-icon>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in temp.names"
          label="原迁移公司名称:"
          :key="item.key"
          :prop="'names.' + index + '.value'"
          :rules="{
            required: true,
            message: '请输入公司名称',
            trigger: 'change',
          }"
        >
          <el-input
            v-model="item.value"
            style="width: 240px; margin-right: 10px"
          ></el-input>
          <el-button
            @click.prevent="remove(index)"
            size="small"
            type="danger"
            plain
            >删除</el-button
          >
        </el-form-item>
        <el-form-item label="迁移新公司账号:" prop="newUserName">
          <el-input v-model="temp.newUserName" style="width: 240px"></el-input>
        </el-form-item>
      </el-form>
    </el-scrollbar>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="migrateSure" size="small"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="错误提示"
    v-model="callbackVisible"
    width="50%"
  >
    <div v-for="(item, index) in list">
      <p style="margin-bottom: 10px">{{ item }}</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="callbackVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="callbackVisible = false" size="small"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { comMigrate, dbComMigrate } from "@/api/org";
import { importExcel } from "@/api/import";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      temp: {
        orgName: "",
        newUserName: "",
        comName: "",
        names: [],
      },
      rules: {
        orgName: [
          { required: true, message: "请输入代账公司名称", trigger: "change" },
        ],
        comName: [
          { required: true, message: "请输入公司名称", trigger: "change" },
        ],
        newUserName: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
      },
      callbackVisible: false,
      list: [],
      path: "",
      rawFile: "",
      contentStyleObj: {},
      dbType: 1,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(230);
  },
  methods: {
    init(flag) {
      this.dbType = flag;
      this.temp = {
        orgName: "",
        newUserName: "",
        comName: "",
        names: [],
      };
      this.dialogFormVisible = true;
    },
    add() {
      this.temp.names.push({
        value: "",
        key: Date.now(),
      });
    },
    remove(index) {
      this.temp.names.splice(index, 1);
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    analyzeExcel() {
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importExcel(param).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.header = res.data.data.data[0].data;
          this.handleSuccess(this.header);
        }
      });
    },
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/公司迁移模板.xls" +
          "?" +
          new Date().getTime()
      );
    },
    handleSuccess(header) {
      let source = header.slice(1);
      if (source.length != 0) {
        if (!this.temp.comName) {
          source.forEach((v, index) => {
            if (index == 0) {
              this.temp.comName = v[0] ? v[0].toString() : "";
            } else {
              let a = v[0] ? v[0].toString() : "";
              this.temp.names.push({
                value: a,
                key: Date.now(),
              });
            }
          });
        } else if (this.temp.comName) {
          source.map((v) => {
            let a = v[0] ? v[0].toString() : "";
            this.temp.names.push({
              value: a,
              key: Date.now(),
            });
          });
        }
      } else {
        this.$qzfMessage("数据为空，请检查表格数据", 1);
      }
    },
    migrateSure() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let comNames = [];
          if (this.temp.names.length != 0) {
            this.temp.names.map((v) => {
              if (v.value) {
                comNames.push(v.value);
              }
            });
          }
          comNames.unshift(this.temp.comName);
          let param = {
            comNames: comNames,
            orgName: this.temp.orgName,
            newUserName: this.temp.newUserName,
          };
          if (this.dbType == 1) {
            comMigrate(param).then((res) => {
              if (res.data.msg == "success") {
                this.$emit("success");
                if (res.data.data != null && res.data.data.length > 0) {
                  this.callbackVisible = true;
                  this.list = res.data.data;
                } else {
                  this.dialogFormVisible = false;
                  this.$qzfMessage("迁移成功");
                }
              }
            });
          } else if (this.dbType == 2) {
            dbComMigrate(param).then((res) => {
              if (res.data.msg == "success") {
                this.$emit("success");
                if (res.data.data != null && res.data.data.length > 0) {
                  this.callbackVisible = true;
                  this.list = res.data.data;
                } else {
                  this.dialogFormVisible = false;
                  this.$qzfMessage("迁移成功");
                }
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 20px;
}
</style>

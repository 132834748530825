<template>
  <el-select
    @change="changeValue"
    v-model="citys"
    placeholder="支持地区"
    size="small"
    clearable
    filterable
    style="width: 110px"
    multiple
  >
    <el-option
      v-for="item in list"
      :key="item.code"
      :label="item.name"
      :value="item.code"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "selectMultipleCity",
  props:{
    citys: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    list() {
      return this.$store.getters["inits/dis"];
    },
  },
  methods: {
    changeValue() {
      this.$emit("update:citys", this.citys);
      this.$emit("success", this.citys);
    },
  },
};
</script>
